export const ROUTES = {
    ROOT_PATH: "/",
    LOGIN: "/login",
    DASHBOARD: "/dashboard",
    MEETING_LIST: "/meetingList",
    MEETING_PREVIEW: "/meetingPreview",
    IN_CALL: "/inCall", 
    ABOUT: "/about",

    //sales-demo-routes
    FEEDBACK:"/feedback",
    SIGNIN: "/signin",
}