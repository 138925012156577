import { store } from "../Redux/store";
import {
  isMobile,
  isTablet,
  isDesktop,
  isMobileOnly,
} from "react-device-detect";

/**
 *
 * @param dateString date to be formatted
 * @returns  format date based on current week or past date
 */
export function getFormattedDateTime(dateString: string) {
  const date = new Date(dateString);

  // Current date and time
  const now = new Date();

  // Check if date is within the current week
  const isCurrentWeek =
    date >=
    new Date(now.getFullYear(), now.getMonth(), now.getDate() - now.getDay());

  // Options for formatting the date
  const options = {
    hour: "2-digit", // 2-digit hour (e.g., 08)
    minute: "2-digit", // 2-digit minute (e.g., 10)
    hour12: true, // use 24-hour format
  };

  // Format the date and time
  let formattedDate;
  if (isCurrentWeek) {
    // Display only day and time for the current week
    const dayOptions = {
      weekday: "long", // full day name (e.g., "Tuesday")
      hourCycle: "h12",
      ...options,
    };
    // @ts-ignore
    formattedDate = new Intl.DateTimeFormat("en-US", dayOptions).format(date);
  } else {
    // Display full date and time for other weeks
    const fullOptions = {
      // weekday: 'long', // full day name (e.g., "Tuesday")
      // year: 'numeric', // 4-digit year (e.g., 2024)
      month: "long", // full month name (e.g., "June")
      day: "numeric", // numeric day (e.g., 20)
      ...options,
    };
    // @ts-ignore
    formattedDate = new Intl.DateTimeFormat("en-US", fullOptions).format(date);
  }

  return formattedDate;
}

export const formatMillisecondsToDate = (millis: number): string => {
  const date = new Date(millis);
  return date.toLocaleString(undefined, {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false, // Set to true for 12-hour format
  });
};

export function convertUTCToLocalTimezone(utcDateString: string) {
  // Parse the UTC datetime string into a Date object
  // Ex: 1726123167 => 12 September 2024 at 12:23:54 pm
  // @ts-ignore
  const utcDate = new Date(utcDateString * 1000); // Adding ' UTC' to indicate it's UTC time
 
  // Get browser's timezone
  const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  // Define options for formatting the date
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    timeZone: timezone,
  };
 
  // Convert to local time and format
  const localDateString = utcDate
    // @ts-ignore
    .toLocaleString("en-US", options)
    .replace(",", ""); // Remove comma for desired format
 
  return localDateString;
}

export function getFilteredConfiguration(configurations: object) {
  // Using Object.entries and reduce to filter properties with true values
  const filteredObj = Object.entries(configurations)
    .filter(([key, value]) => value === true)
    .reduce((acc, [key, value]) => {
      // @ts-ignore
      acc[key] = value;
      return acc;
    }, {});

  return filteredObj;
}

export const handleKeyPressForName = (
  e: React.KeyboardEvent<HTMLInputElement>
) => {
  // Allow only alphabetic characters and whitespace
  if (
    (e.key >= "A" && e.key <= "Z") || // A-Z
    (e.key >= "a" && e.key <= "z") || // a-
    (e.key >= "0" && e.key <= "9") // a-z
  ) {
    // Append the typed character to the current text
    return;
  }
  // Prevent default behavior for all other keys
  e.preventDefault();
};

export const validateEmail = (email: string): boolean => {
  // Regular expression for validating email addresses
  if (!email) {
    return true;
  }
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return emailRegex.test(email);
};

export const handleKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
  console.log("handleKeyPress e.key: ", e.key);

  // Allow only alphabetic characters and whitespace
  if (
    (e.key >= "A" && e.key <= "Z") || // A-Z
    (e.key >= "a" && e.key <= "z") || // a-z
    e.key === " " || // space
    e.key === "Delete" ||
    e.key === "Backspace" ||
    e.key === "Enter"
  ) {
    // Append the typed character to the current text
    return;
  }
  // Prevent default behavior for all other keys
  e.preventDefault();
};

export const validatePhoneNumber = (
  event: React.KeyboardEvent<HTMLInputElement>
) => {
  console.log("event: ", event.key);
  const regex = /^[0-9\+]*$/;

  if (
    event.key === "Delete" ||
    event.key === "Backspace" ||
    event.key === "ArrowLeft" ||
    event.key === "ArrowRight" ||
    regex.test(event.key)
  ) {
    // Allow the key press
    return;
  } else {
    // Prevent the key press if it doesn't match the regex or is not Delete/Backspace
    event.preventDefault();
  }
};

export function containsOnlySpaces(str: string) {
  if (str === undefined) return true;
  return /^\s*$/.test(str);
}

export function getLastPartOfURL(): string {
  // Get the URL of the current page
  const currentURL = window.location.href;

  // Split the URL into an array of segments using the forward slash as a delimiter
  const segments = currentURL?.split("/");

  // Access the last segment of the array to get the last part of the URL
  const lastPartOfURL = segments[segments?.length - 1];
  console.log("_getGuestRoomName lastPartOfURL: ", lastPartOfURL);
  return lastPartOfURL;
}

//export function getRoomFromURL() {
  // Get the URL of the current page
  //const currentURL = window.location.href;
  //let segments;

//   // Split the URL into an array of segments using the forward slash as a delimiter
//   if (currentURL.includes("/")) {
//     segments = currentURL?.split("/");
//   } else if (currentURL.includes("/#/")) {
//     segments = currentURL?.split("/#/");
//   } else if (currentURL.includes("#/")) {
//     segments = currentURL?.split("#/");
//   } else {
//     segments = currentURL?.split("");
//   }

//   // Access the last segment of the array to get the last part of the URL
//   let getRoomFromURL = segments[segments?.length - 1];
//   getRoomFromURL = getRoomFromURL.replace("#/", "");

//   // Split the input string at the "?"
//   const [roomId, queryString] = getRoomFromURL.split("?");

//   store.dispatch({ type: "SET_ROOM_ID", payload: roomId });

//   // Create a URLSearchParams object to handle the query string
//   const params = new URLSearchParams(queryString);

//   // Extract the room_name parameter
//   const roomName = params.get("room_name");

//   console.log(
//     "_getGuestRoomName getRoomFromURL: ",
//     getRoomFromURL,
//     roomId,
//     roomName
//   );

//   return roomName;
// }

const isIpad = () => {
  return /iPad/i.test(navigator.userAgent);
};

export const isMobileDevice =
  !isDesktop && !isTablet && isMobile && isMobileOnly;
export const isTabletOrIpad =
  !isDesktop && !isMobileOnly && (isTablet || isIpad());
export const isDesktopDevice =
  !isMobile && !isMobileOnly && !isTablet && isDesktop;
export const isBiggerTabOrIpad =
  (isTablet || isIpad()) && window.innerWidth >= 1024;

export const getCallTileSize = (
  size: any,
  tileCount: number,
  participants: Array<any>,
  verticalMargin = 0,
  horizontalMargin = 0
) => {
  if (size) {
    let tileSize = getTilePlacements(
      size?.width,
      size?.height,
      Math.min(participants.length, tileCount),
      verticalMargin,
      horizontalMargin
    );
    return tileSize;
  }
};

const getTilePlacements = (
  containerWidth: number,
  containerHeight: number,
  noOfUsers: number,
  verticalMargin = 0,
  horizontalMargin = 0
): any => {
  // console.log(
  //   "getTilePlacements =========",
  //   containerWidth, containerHeight, verticalMargin, horizontalMargin, noOfUsers
  // );
  let possibleLayouts = [];
  let bestSuitableLayout = { tileSize: {} };
  for (let tilesPerRow: number = 1; tilesPerRow <= noOfUsers; tilesPerRow++) {
    let totalRows = Math.ceil(noOfUsers / tilesPerRow); // 3 | 2
    possibleLayouts.push(
      getPossibleTileSizes(
        containerWidth,
        containerHeight,
        verticalMargin,
        horizontalMargin,
        totalRows,
        tilesPerRow
      )
    );
  }

  if (containerWidth > containerHeight) {
    bestSuitableLayout = possibleLayouts.reduce(
      (prevLayoutWithMoreWidth, layout) => {
        return layout?.tileSize.tileWidth >
          prevLayoutWithMoreWidth?.tileSize.tileWidth
          ? layout
          : prevLayoutWithMoreWidth;
      },
      possibleLayouts[0]
    );
  } else {
    bestSuitableLayout = possibleLayouts.reduce(
      (prevLayoutWithMoreHeight, layout) => {
        return layout?.tileSize.tileHeight >
          prevLayoutWithMoreHeight?.tileSize.tileHeight
          ? layout
          : prevLayoutWithMoreHeight;
      },
      possibleLayouts[0]
    );
  }
  console.log(
    "bestSuitableLayout =========",
    bestSuitableLayout?.tileSize,
    containerWidth,
    containerHeight
  );
  return bestSuitableLayout?.tileSize;
};

const getPossibleTileSizes = (
  containerWidth: number,
  containerHeight: number,
  verticalMargin: number,
  horizontalMargin: number,
  totalRows: number,
  tilesPerRow: number
) => {
  let layout = {
    totalRows,
    tilesPerRow,
    tileSize: { tileHeight: 0, tileWidth: 0 },
  };
  let tileWidth = containerWidth / tilesPerRow; // 1200 / 2 = 600  | 400
  tileWidth -= (tilesPerRow + 1) * horizontalMargin;
  let tileHeight = tileWidth * (9 / 16); // 338 |

  let totalTileHeight = tileHeight * totalRows; // 1012
  if (totalTileHeight < containerHeight) {
    let totalVerticalMargin = (totalRows + 1) * verticalMargin;
    totalTileHeight += totalVerticalMargin;
    if (totalTileHeight < containerHeight) {
      layout.tileSize = { tileWidth, tileHeight };
    }
  }

  tileHeight = containerHeight / totalRows;
  tileHeight -= (totalRows + 1) * verticalMargin;
  tileWidth = tileHeight * (16 / 9); // 1200 / 2 = 600  | 400

  let totalTileWidth = tileWidth * tilesPerRow; // 1012
  if (totalTileWidth < containerWidth) {
    let totalHorizontalMargin = (tilesPerRow + 1) * horizontalMargin;
    totalTileWidth += totalHorizontalMargin;
    if (totalTileWidth < containerWidth) {
      layout.tileSize = { tileWidth, tileHeight };
    }
  }
  return layout;
};

export const getThumbnailTileSize = (
  thumbnailPanelSize: any,
  verticalMargin: number,
  horizontalMargin: number
) => {
  if (
    thumbnailPanelSize &&
    thumbnailPanelSize.width > 0 &&
    thumbnailPanelSize.height > 0
  ) {
    // alert("thumbnailPanelSize: " + JSON.stringify(thumbnailPanelSize));
    let containerWidth = thumbnailPanelSize.width;
    let containerHeight = thumbnailPanelSize.height;
    let tileHeight = 1;
    let tileWidth = 1;
    let noOfUsersToFit = 1;

    // alert(
    //   "containerWidth: " +
    //     containerWidth +
    //     "containerHeight:" +
    //     containerHeight +
    //     "horizontalMargin" +
    //     horizontalMargin
    // );
    if (containerWidth < containerHeight) {
      tileWidth = containerWidth; // no need to consider horizontalMargin as there is single thumbnail horizontally and right and left padding already exists for the panel
      tileHeight = tileWidth * (9 / 16);
      noOfUsersToFit = Math.floor(containerHeight / tileHeight);
      // alert("noOfUsersToFit: before" + noOfUsersToFit);
      let totalHeightRequiredForTiles =
        tileHeight * noOfUsersToFit + (noOfUsersToFit - 1) * verticalMargin;
      while (totalHeightRequiredForTiles > containerHeight && noOfUsersToFit) {
        noOfUsersToFit -= 1;
        totalHeightRequiredForTiles =
          tileHeight * noOfUsersToFit + (noOfUsersToFit - 1) * verticalMargin;
      }
    } else {
      tileHeight = containerHeight; // no need to consider verticalMargin as there is single thumbnail vertically and top and bottom padding already exists for the panel
      tileWidth = tileHeight * (16 / 9);
      noOfUsersToFit = Math.floor(containerWidth / tileWidth);
      let totalWidthRequiredForTiles =
        tileWidth * noOfUsersToFit + (noOfUsersToFit - 1) * horizontalMargin;
      while (totalWidthRequiredForTiles > containerWidth && noOfUsersToFit) {
        noOfUsersToFit -= 1;
        totalWidthRequiredForTiles =
          tileWidth * noOfUsersToFit + (noOfUsersToFit - 1) * horizontalMargin;
      }
    }
    // alert("noOfUsersToFit: " + noOfUsersToFit);
    return { totalRows: 1, tileWidth, tileHeight, noOfUsersToFit };
  }
  return { totalRows: 1, tileWidth: 0, tileHeight: 0, noOfUsersToFit: 0 };
};

export const getAddressBarHeight = () => {
  // Create a temporary div to calculate full viewport height
  const tempDiv = document.createElement("div");
  tempDiv.style.height = "100vh"; // This will represent the full viewport height
  document.body.appendChild(tempDiv);

  // Get the height of the full viewport
  const fullViewportHeight = tempDiv.clientHeight;
  document.body.removeChild(tempDiv);

  // Calculate the difference between the full viewport and current visible height
  const addressBarHeight = fullViewportHeight - window.innerHeight;

  return addressBarHeight > 0 ? addressBarHeight : 0;
};

/**
 * this function is required specifically to find exact width and height excluding padding
 * for the parent container of call tiles and side tiles, as well as vertical and horizontal
 * margin required between call tiles. When parent container width is more that height,
 * vertical margin is not required and when height is more that width, horizontal margin is
 * not required
 * @param element (call container or side panel container)
 * @returns
 */
export const getElementSizeExcludingPadding = (
  element: HTMLElement,
  considerWidthHeightRelation = false
): { width: number; height: number; vGap: number; hGap: number } => {
  const computedStyle = getComputedStyle(element);
  // alert(
  //   "computedStyle: " + computedStyle.paddingTop + computedStyle.paddingBottom
  // );
  const boundingRect = element.getBoundingClientRect();
  // alert("boundingRect: " + boundingRect);
  const size = {
    width:
      boundingRect.width -
      parseInt(computedStyle.paddingLeft) -
      parseInt(computedStyle.paddingRight),
    height:
      boundingRect.height -
      parseInt(computedStyle.paddingTop) -
      parseInt(computedStyle.paddingBottom),
    vGap: parseInt(computedStyle.gap),
    hGap: parseInt(computedStyle.gap),
  };

  if (considerWidthHeightRelation) {
    if (boundingRect.width > boundingRect.height) {
      size.vGap = 0;
    } else {
      size.hGap = 0;
    }
  }
  // console.log('getElementSizeExcludingPadding:', element.id, size)
  return size;
};
/**
 * Formats a number to include leading zero if less than 10.
 * @param num The number to format.
 * @returns The formatted number as a string.
 */

const formatNumber = (num: number): string => {
  return num < 10 ? `0${num}` : `${num}`;
};

// Timer calculated by deleting the current time from the room created unix timestamp
export const calculateMeetingTime = (unixTimestamp: number) => {
  let difference = 0;
  // Ensure unixTimestamp is a valid number
  if (isNaN(unixTimestamp) || unixTimestamp <= 0) {
    return "Invalid meeting timestamp.";
  }
  const now = Math.floor(Date.now() / 1000);
  difference = now - unixTimestamp;

  if (difference < 0) {
    return "Meeting has already started or ended.";
  }

  const seconds = Math.floor(difference % 60) || 0;
  const minutes = Math.floor((difference / 60) % 60) || 0;
  const hours = Math.floor((difference / (60 * 60)) % 24) || 0;
  const days = Math.floor(difference / (60 * 60 * 24)) || 0;

  return ` ${formatNumber(hours)} : ${formatNumber(minutes)} : ${formatNumber(
    seconds
  )}`;
};


export function delay(millisec:number): Promise<void> {
  return new Promise((resolve, reject) => { setTimeout(() => resolve(), millisec)});
}