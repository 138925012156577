import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//Components
import LandingPage from "./LandingPage";

//Custom Components
import LoadingSpinner from "../../Common Components/LoadingSpinner/LoadingSpinner";

//Actions
import {
  getMeetingList,
  viewCallSummary,
} from "../../Redux/Actions/MeetingListAction";
import { logoutAction } from "../../Redux/Actions/LoginAction";
import { clearMeetingDataFromStore } from "../../Redux/Actions/StoreAction";

//Constants
import { ROUTES } from "../../Routes/RouteConstants";
import {
  CLEAR_NOTIFICATIONS,
  SET_CREATE_MEETING,
} from "../../Redux/ReduxConstants";

//Store
import proConfService from "../../Services/ProConfService";
import { useDispatch } from "react-redux";

/**
 *
 * @returns jsx for Meeting list/ landing page component
 * this page wiil be displayed after Moderator Login
 */
export default function LandingPageContainer() {
  const navigate = useNavigate();

  //state variables
  const [isLoading, setIsLoading] = useState(false);
  const [meetingList, setMeetingList] = useState<any[]>([]);
  const isInitDone = useSelector(
    (state: any) => state.ProConfReducer.isInitDone
  );
  const dispatch = useDispatch();

  /**
   * @method getMeetingsList
   * @description get the list of Meeting created by logged-in user
   * @returns list of previous meetings created by logged-in user/Moderator
   */
  async function getMeetingsList() {
    setIsLoading(true);

    const meetingList = await getMeetingList();
    setMeetingList(meetingList); // Update state with fetched data
    setIsLoading(false);
  }

  /**
   * @method getCallSummary
   * @param roomId meeting's unique id
   * @description User can get the the details of the past meetings
   * if summary is available (transcription needs to be ON)
   * @returns the call summary of a specified/selected meeting
   */
  async function getCallSummary(roomId: string) {
    setIsLoading(true);
    const summary = await viewCallSummary(roomId);
    setIsLoading(false);
    return summary;
  }

  /**
   * @method onCreateMeetingClick
   * @description Go to the meeting preview page for devices selection
   * Only Moderator can create meetings
   */
  async function onCreateMeetingClick() {
    if (isInitDone) {
      await proConfService?.getCallConfig();
      navigate(ROUTES.MEETING_PREVIEW);
    }
  }

  /**
   * @method joinProConfMeeting
   * @description Go to the meeting preview page for devices selection
   * Moderator will be joined as a guest for existing meetings
   * or new meeting created by other moderator
   */
  async function joinProConfMeeting() {
    dispatch({
      type: SET_CREATE_MEETING,
      payload: false,
    });
    navigate(ROUTES.MEETING_PREVIEW);
  }

  const onLogoutClick = async () => {
    logoutAction();
    navigate("/");
  };

  useEffect(() => {
    clearMeetingDataFromStore();
    dispatch({ type: CLEAR_NOTIFICATIONS });
    setIsLoading(true);
  }, []);

  useEffect(() => {
    // Get meeting list after init done
    if (!isInitDone) {
      setIsLoading(true);
    } else {
      getMeetingsList();
    }
  }, [isInitDone]);

  return (
    <>
      {isLoading && <LoadingSpinner />}
      <LandingPage
        meetingList={meetingList}
        getCallSummary={getCallSummary}
        onCreateMeetingClick={onCreateMeetingClick}
        joinMeeting={joinProConfMeeting}
        onLogoutClick={onLogoutClick}
      />
    </>
  );
}
