// Define Action Types
export const INIT_PROCONF_SUCCESS = "INIT_PROCONF_SUCCESS";
export const CONNECT_SUCCESS = "CONNECT_SUCCESS";
export const JOIN_ROOM_SUCCESS = "JOIN_ROOM_SUCCESS";
export const PROCONF_ERROR = "PROCONF_ERROR";
export const CLEAR_ROOM = "CLEAR_ROOM";
export const IS_PROCONF_INIT = "IS_PROCONF_INIT";
export const SET_REMOTE_SS = "SET_REMOTE_SS";
export const CALL_IN_PROGRESS = "CALL_IN_PROGRESS";
export const SET_MEETING_TIME = "SET_MEETING_TIME";

//Notifications
export const ERROR_NOTIFICATION = "ERROR_NOTIFICATION";
export const WARNING_NOTIFICATION = "WARNING_NOTIFICATION";
export const SUCCESS_NOTIFICATION = "SUCCESS_NOTIFICATION";
export const CLEAR_NOTIFICATIONS = "CLEAR_NOTIFICATIONS";

//Participant
export const ADD_PARTICIPANT = "ADD_PARTICIPANT";
export const REMOVE_PARTICIPANT = "REMOVE_PARTICIPANT";
export const UPDATE_PARTICIPANT = "UPDATE_PARTICIPANT";
export const SET_LOCAL_PARTICIPANT = "SET_LOCAL_PARTICIPANT";
export const SET_SCREEN_SHARING_PARTICIPANT = "SET_SCREEN_SHARING_PARTICIPANT";
export const CLEAR_PARTICIPANTS = "CLEAR_PARTICIPANTS";

//Login
export const SET_AUTH_TOKEN = "SET_AUTH_TOKEN";
export const GET_AUTH_TOKEN = "GET_AUTH_TOKEN";
export const SET_AVAILABLE_CONFIGURATIONS = "SET_AVAILABLE_CONFIGURATIONS";
export const LOGOUT = "LOGOUT";
export const SET_PROFILE_NAME = "SET_PROFILE_NAME";

//Guest User Login
export const SET_GUEST_USER_NAME = "SET_GUEST_USER_NAME";
export const SET_ROOM_NAME = "SET_ROOM_NAME";
export const SET_ROOM_ID = "SET_ROOM_ID";
export const ROOM_DATA = "ROOM_DATA";
export const CLEAR_ROOM_REDUCER = "CLEAR_ROOM_REDUCER";

// Room reducer
export const SET_CREATE_MEETING = "SET_CREATE_MEETING";

//Toolbar
export const ON_MIC_MUTE_UNMUTE = "ON_MIC_MUTE_UNMUTE";
export const ON_VIDEO_MUTE_UNMUTE = "ON_VIDEO_MUTE_UNMUTE";
export const ON_TRANSCRIPT_TOGGLE = "ON_TRANSCRIPT_TOGGLE";
export const ON_TRANSCRIPT_ENABLED = "ON_TRANSCRIPT_ENABLED";
export const ON_PARTICIPANT_PANEL_TOGGLE = "ON_PARTICIPANT_PANEL_TOGGLE";
export const ON_CHANGE_LAYOUT = "ON_CHANGE_LAYOUT";
export const ON_RECORDING_TOGGLE = "ON_RECORDING_TOGGLE";
export const ON_SCREEN_SHARE_TOGGLE = "ON_SCREEN_SHARE_TOGGLE";
export const ON_SPEAKER_MUTE_UNMUTE = "ON_SPEAKER_MUTE_UNMUTE";
export const ON_MORE_ACTIONS = "ON_MORE_ACTIONS";
export const ON_MORE_ACTIONS_MOBILE = "ON_MORE_ACTIONS_MOBILE";
export const ON_END_CALL = "ON_END_CALL";
export const RESET_TOOLBAR_STATE = "RESET_TOOLBAR_STATE";
export const SET_SELECTED_SPEAKER = "SET_SELECTED_SPEAKER";
export const SET_SELECTED_MIC = "SET_SELECTED_MIC";
export const SET_SELECTED_CAMERA = "SET_SELECTED_CAMERA";
export const ON_NOISE_CANCELLATION_TOGGLE = "ON_NOISE_CANCELLATION_TOGGLE";
export const SET_TRANSCRIPTION_STATE = "SET_TRANSCRIPTION_STATE";
export const REMOVE_SCREEN_SHARING_PARTICIPANT =
  "REMOVE_SCREEN_SHARING_PARTICIPANT";
export const RESET_RECORDING_STATE = "RESET_RECORDING_STATE";

//Incall
export const SET_TRANSCRIPTIONS = "SET_TRANSCRIPTIONS";
export const CLEAR_INCALL_REDUCER = "CLEAR_INCALL_REDUCER";
export const SET_ROOM_ENDED = "SET_ROOM_ENDED";
export const SET_AUDIO_INPUT_DEVICES = "SET_AUDIO_INPUT_DEVICES";
export const SET_AUDIO_OUTPUT_DEVICES = "SET_AUDIO_OUTPUT_DEVICES";
export const SET_VIDEO_DEVICES = "SET_VIDEO_DEVICES";
export const SET_AUDIO_PERMISSION = "SET_AUDIO_PERMISSION";
export const SET_VIDEO_PERMISSION = "SET_VIDEO_PERMISSION";
export const CLEAR_DEVICES = "CLEAR_DEVICES";
