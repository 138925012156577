import React from "react";


//Constants
import { ROUTES } from "./RouteConstants";

//Components
import LandingPageContainer from "../Components/LandingPage/LandingPageContainer";
import CreateMeetingContainer from "../Components/MeetingPreview/MeetingPreviewContainer";
import CallScreenContainer from "../Components/CallScreen/CallScreenContainer";
import RequestDemoContainer from "../Sales-Flow/RequestDemo/RequestDemoContainer";
import FeedbackPageContainer from "../Sales-Flow/FeedbackPage/FeedbackPageContainer";
import LoginPage from "../Sales-Flow/Login/LoginPage";



const routes = [
    {
        path: ROUTES.MEETING_LIST,
        exact: true,
        Component: () => (
            < LandingPageContainer />
        ),
    },
    {
        path: ROUTES.MEETING_PREVIEW,
        exact: true,
        Component: () => (
            < CreateMeetingContainer />
        ),
    },
    {
        path: ROUTES.IN_CALL,
        exact: true,
        Component: () => (
            < CallScreenContainer />
        ),
    },


    //sales-demo
    {
        path: ROUTES.ROOT_PATH,
        exact: true,
        Component: () => (
            < RequestDemoContainer />
        ),
    },
    {
        path: ROUTES.FEEDBACK,
        exact: true,
        Component: () => (
            < FeedbackPageContainer />
        ),
    },
    {
        path: ROUTES.SIGNIN,
        exact: true,
        Component: () => (
            < LoginPage />
        ),
    }

]

export default routes;