import { FunctionComponent, RefObject } from "react";
import Avatar from "react-avatar";

//Store
import { store } from "../../Redux/store";

//Styles
import "./ProfileMenu.scss";
import proConfService from "../../Services/ProConfService";
import { ROUTES } from "../../Routes/RouteConstants";

interface ProfileMenuType {
  className?: string;
  buttonRef: RefObject<HTMLButtonElement>;
  toggleLogoutPopup?: () => void;
}

const ProfileMenu: FunctionComponent<ProfileMenuType> = ({
  className = "",
  buttonRef,
  toggleLogoutPopup,
}) => {
  const userName = store.getState()?.LoginReducer.userName;
  return (
    <button
      className={className}
      ref={buttonRef}
      onClick={toggleLogoutPopup}
      // if tracks are getting created on meeting preview page, disable logout button to restrict user from logout
      // otherwise camera light remains ON
      disabled={
        proConfService.tracks?.length === 0 &&
        window.location?.hash?.includes(ROUTES.MEETING_PREVIEW)
      }
    >
      <Avatar name={userName} round={true} textSizeRatio={3} size={"40"} />
    </button>
  );
};

export default ProfileMenu;
