import { FunctionComponent } from "react";
import LoginInnerContainer from "../Login/LoginInnerContainer";
import "./LoginPage.scss";

const LoginPage: FunctionComponent = () => {
  return (
    <div className="login-page">
      <LoginInnerContainer />
    </div>
  );
};

export default LoginPage;
