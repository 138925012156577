import { useEffect, useState } from "react";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";

//Components
import StartMeetingForm from "./StartMeeting";
import LoadingSpinner from "../../Common Components/LoadingSpinner/LoadingSpinner";

//Action
import {
  fetchTokenizeURL,
  setRoomId,
  updateTimeStamp,
  updateVisitedCount,
} from "../Redux/Actions/SalesAction";
import { callJoinAsGuestnAPI } from "../../Redux/Actions/LoginAction";
import { setErrorNotification } from "../../Redux/Actions/NotificationAction";

//Utility
import { getLastPartOfURL } from "../../Utility/Utils";

//Constants
import { API_STATUS } from "../../Constants/AppConstants";
import { ROUTES } from "../../Routes/RouteConstants";

//Services
import proConfService from "../../Services/ProConfService";
import { store } from "../../Redux/store";
import { setDataIntoReduxStore } from "../../Redux/Actions/StoreAction";
import { SET_PROFILE_NAME } from "../../Redux/ReduxConstants";
import { isValidRoomname, ProConfErrorCode, ProConfErrorMessages } from "proconf-web-sdk";
import { getTranslation } from "../../Resources/Localization/i18n";

const StartMeetingContainer = ({}) => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [isParticipant, setIsParticipant] = useState(false);
  const [pid, setParticipantId] = useState("");
  const [roomName, setRoomName] = useState("");
  const [resetRoom, setResetRoom] = useState(false);

  const location = useLocation();
  const path = location.pathname.split("/").pop(); // Extracts 'meetingId' from the URL
  console.log("meetingId. path: ", path);

  const [searchParams] = useSearchParams();

  let roomId = "";

  useEffect(() => {
    getParams();
  }, []);

  const getParams = () => {
    const pid = searchParams.get("pid") as string;
    const rn = searchParams.get("rn") as string;
    const room_id = searchParams.get("room_id") as string;

    setParticipantId(pid);
    setRoomName(rn);

    console.log("guestflow rn: meetingId", path, pid, rn, room_id);

    if (path && pid && rn) {
      store.dispatch({ type: "SET_MEETING_ID", payload: path });
      store.dispatch({ type: "SET_PARTICIPANT_ID", payload: pid });
      store.dispatch({ type: "SET_ROOM_NAME", payload: rn });
      store.dispatch({ type: "SET_ROOM_ID", payload: room_id });
      console.log("meetingId: getParams", store.getState().SalesReducer);

      setIsParticipant(true);
    } else {
      roomId = getLastPartOfURL();
      console.log("roomId: ", roomId);
      updateVisitedCount(roomId);

      setRoomId(roomId);
      store.dispatch({ type: "SET_MEETING_ID", payload: roomId });
    }
  };

  const onStartMeeting = async (userName: string, roomName: string) => {
    setIsLoading(true);
    const isValid = isValidRoomname(roomName);    
    if (isValid === ProConfErrorCode.NO_ERROR) {
      store.dispatch({ type: "SET_ROOM_NAME", payload: roomName });
      let response = await fetchTokenizeURL(
        userName,
        roomName,
        store.getState()?.SalesReducer?.meetingId
      );

      if (response?.status === API_STATUS.SUCCESS) {
        await proConfService.initProConf();
        navigate(ROUTES.MEETING_PREVIEW);
      } else {
        setErrorNotification(response?.response?.data?.error?.message);
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setResetRoom(true);
      setErrorNotification(ProConfErrorMessages[isValid]);
    }
  };

  const onJoinMeeting = async (userName: string) => {
    setIsLoading(true);
    const isValid = isValidRoomname(roomName);
    if (isValid === ProConfErrorCode.NO_ERROR) {
      store.dispatch({ type: "SET_PARTICIPANT_NAME", payload: userName });
      store.dispatch({ type: "SET_ROOM_NAME", payload: roomName });

      let response = await callJoinAsGuestnAPI(
        userName,
        store?.getState().RoomReducer?.roomId,
        roomName
      );

      console.log(
        "userName, store.getState()?.RoomReducer?.roomName: ",
        userName,
        roomName,
        pid
      );

      await updateTimeStamp("joining");
      if (response?.status === API_STATUS.SUCCESS) {
        await proConfService.initProConf();
        setDataIntoReduxStore(SET_PROFILE_NAME, userName);
        navigate(ROUTES.MEETING_PREVIEW);
      } else {
        setErrorNotification(response?.response?.data?.error?.message);
      }
      setIsLoading(false);
    } else {
      setIsLoading(false);
      setResetRoom(true);
      setErrorNotification(ProConfErrorMessages[isValid]);
    }
  };

  const getUserDetails = () => {
    return "";
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}

      <StartMeetingForm
        onStartMeeting={(userName: string, roomName: string) =>
          onStartMeeting(userName, roomName)
        }
        onJoinMeeting={(userName: string) => onJoinMeeting(userName)}
        isParticipant={isParticipant}
        getUserDetails={getUserDetails}
        resetRoom={resetRoom}
        setResetRoom={setResetRoom}
      />
    </>
  );
};

export default StartMeetingContainer;
