import { FunctionComponent } from "react";
import { Switch, Select } from "antd";
import "./PanelTitleBar.scss";
import CloseIcon from "../../Resources/InCallImages/ClosePopupIcon.svg";
import { getTranslation } from "../../Resources/Localization/i18n";

export type PanelTitleBarType = {
  className?: string;
  titleText?: string;
  toggleButton?: boolean;
  dropdown?: boolean;
  checkedTranscription?: boolean;
  onClickCloseIcon?: () => void;
  onToggleTranscription?: () => void;
};

const PanelTitleBar: FunctionComponent<PanelTitleBarType> = ({
  className = "",
  titleText,
  toggleButton = false,
  dropdown = false,
  checkedTranscription = false,
  onClickCloseIcon,
  onToggleTranscription,
}) => {
  return (
    <div className={`paneltitlebar ${className}`}>
      <div className="paneltitle">
        <div className="titletext">{titleText}</div>
        {toggleButton && (
          <div className="togglebutton">
            <Switch
              className="switch1"
              style={{ width: 26 }}
              checked={checkedTranscription}
              onChange={onToggleTranscription}
              id={'transcript-switch'}
            />
          </div>
        )}
        {dropdown && (
          <Select
            className="dropdown"
            placeholder={getTranslation("language")}
            size="small"
            style={{ width: "100px" }}
            filterOption={(input: any, option: any) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            virtual={false}
            defaultValue={getTranslation("english")}
          >
            <Select.Option value="English">
              {getTranslation("english")}
            </Select.Option>
            <Select.Option value="Spanish">
              {getTranslation("spanish")}
            </Select.Option>
            <Select.Option value="German">
              {getTranslation("german")}
            </Select.Option>
            <Select.Option value="Italian">
              {getTranslation("italian")}
            </Select.Option>
          </Select>
        )}
      </div>
      <button className="closebutton1" onClick={onClickCloseIcon}>
        <img
          className="closebuttoncontainer-icon1"
          alt="ClosePopupIcon"
          src={CloseIcon}
        />
      </button>
    </div>
  );
};

export default PanelTitleBar;
