import { FunctionComponent, useEffect, useState } from "react";
//import "antd/dist/antd.min.css";
// import { Input } from "antd";
import { DotLottiePlayer } from "@dotlottie/react-player";
import { useLocation, useNavigate } from "react-router-dom";
import proconfLogo from "../../Resources/Images/PLogo.png";
import "./LoginInnerContainer.scss";
import {
  loginWithGoogle,
  loginWithLinkedin,
} from "../Redux/Actions/SalesAction";
import { API_STATUS } from "../../Constants/AppConstants";
import { ROUTES } from "../../Routes/RouteConstants";
import LinkedInLogo from "../../Resources/Images/sales-images/linked-in-image@2x.png";
import { getTranslation } from "../../Resources/Localization/i18n";
import proConfService from "../../Services/ProConfService";
import LoadingSpinner from "../../Common Components/LoadingSpinner/LoadingSpinner";
import { useDispatch } from "react-redux";
import { CLEAR_ROOM_REDUCER } from "../../Redux/ReduxConstants";
import { isDesktop } from "react-device-detect";

export type LoginInnerContainerType = {
  className?: string;
  //showLoginWithSocialMedia?: boolean;
};

declare global {
  interface Window {
    google: any;
  }
}

const LoginInnerContainer: FunctionComponent<LoginInnerContainerType> = ({
  className = "",
  //showLoginWithSocialMedia = true,
}) => {
  const navigate = useNavigate();
  const [showSpinner, setShowSpinner] = useState(false);
  const dispatch = useDispatch();
  const location = useLocation();

  /*const onBackButtonClick = useCallback(() => {
      navigate("/");
    }, [navigate]);*/

  // Linked-in signin constants
  const clientId = process.env.REACT_APP_LINKEDIN_CLIENT_ID; // Replace with your LinkedIn app's client ID
  const redirectUri = process.env.REACT_APP_DEMOAPP_BASE_URL; // Your backend's callback URL
  const scope = "profile openid email"; // Scope to request email and basic profile data

  useEffect(() => {
    (async () => {
      dispatch({ type: CLEAR_ROOM_REDUCER });
      // Load the Google API script
      // Dynamically load the Google API script
      const script = document.createElement("script");
      script.src = "https://accounts.google.com/gsi/client";
      script.async = true;
      document.body.appendChild(script);

      // Once the script is loaded, initialize the Google Sign-In button
      script.onload = async () => {
        window.google.accounts.id.initialize({
          client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID, // Replace with your actual Google Client ID
          callback: handleCredentialResponse,
        });

        window.google.accounts.id.renderButton(
          document.getElementById("google-signin-button"),
          {
            size: "large",
            logo_alignment: "center",
            text: "continue_with",
            width: isDesktop ? "260" : "auto",
          }
        );

        // Ensure the iframe inside the button is 100% width
        setTimeout(function () {
          const googleContainer = document.getElementById(
            "google-signin-container"
          );
          if (googleContainer) {
            googleContainer.style.padding = "0.0255%";
          }

          var iframe = document.querySelector(
            "#google-signin-button iframe"
          ) as HTMLIFrameElement;
          if (iframe) {
            iframe.style.width = "100%";

            const el = document.querySelector(
              "iframe #container"
            ) as HTMLDivElement;
            if (el) {
              el.style.width = "100% !important";
            }
          }
        }, 200); // Allow a short delay to ensure iframe is rendered
      };

      // Load Linked-in script
      // Check if the URL contains the 'code' parameter after LinkedIn redirects
      const urlParams = new URLSearchParams(location.search);
      console.log("urlParams: ", urlParams);
      const authorizationCode = urlParams.get("code");
      console.log("authorizationCode: ", authorizationCode);
      if (authorizationCode) {
        setShowSpinner(true);
        const res = await loginWithLinkedin(authorizationCode);
        console.log("linked in res: ", res);
        //@ts-ignore
        if (res.status === API_STATUS.SUCCESS) {
          await proConfService.initProConf();
          navigate(ROUTES.MEETING_PREVIEW);
        } else {
          setShowSpinner(false);
        }
      }

      // Cleanup script when the component is unmounted
      return () => {
        document.body.removeChild(script);
        setShowSpinner(false);
      };
    })();
  }, []);

  // GOOGLE: Callback function for successful sign-in
  const handleCredentialResponse = async (response: any) => {
    // Log the response to the console (it contains the ID token)
    console.log("Credential Response:", response);
    const idToken = response.credential; // Extract the ID token from the response
    // You can now send this ID token to your server for verification
    setShowSpinner(true);
    const res = await loginWithGoogle(idToken);
    if (res.status === API_STATUS.SUCCESS) {
      await proConfService.initProConf();
      navigate(ROUTES.MEETING_PREVIEW);
      setShowSpinner(false);
    } else {
      setShowSpinner(false);
    }
  };

  // Function to handle LinkedIn Login
  const handleLinkedInLogin = () => {
    const authUrl = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${clientId}&redirect_uri=${encodeURIComponent(
      redirectUri as string
    )}&scope=${encodeURIComponent(scope)}`;
    window.location.href = authUrl; // Redirect to LinkedIn login page
  };

  return (
    <div className={`login-inner-container ${className}`}>
      {showSpinner && <LoadingSpinner />}
      <div className="login-fields">
        <img
          className="proconf-logo-icon"
          alt="ProCONF logo"
          src={proconfLogo}
        />

        <div className="title116">{getTranslation("instantLogin")}</div>
        {/* {showLoginWithSocialMedia && ( */}
        <div className="login-with-social-media">
          <div className="login-options">
            <div id="google-signin-button"></div>

            {/* <button 
              className="secondarybuttonlogin"
              id="google-signin-container">
              <div className="innerframebtn5">
                <div className="login-using-logo">
                  <img
                    className="google-image-icon"
                    alt=""
                    src="/google-image@2x.png"
                  />
                </div>
                <b className="banner3request10">Continue With Google</b>
              </div>
            </button> */}
            <button
              className="secondarybuttonlogin"
              onClick={handleLinkedInLogin}
              style={{
                width: "100%",
                color: "#3c4043",
                fontSize: "0.875rem",
                alignSelf: "flex-start",
              }}
            >
              <div className="innerframebtn5">
                <div className="login-using-logo">
                  <img
                    className="google-image-icon"
                    alt=""
                    src={LinkedInLogo}
                  />
                </div>
                <span className="banner3request10">
                  {getTranslation("linkedInButton")}
                </span>
              </div>
            </button>
          </div>

          {/* <div className="orcontainer">
              <div className="separator" />
              <div className="or">OR</div>
              <div className="separator" />
            </div> */}

          {/* <button className="secondarybuttonlogin">
              <div className="innerframebtn5">
                <div className="login-using-logo2">
                  <img
                    className="google-image-icon"
                    alt=""
                    src="/linked-in-image1@2x.png"
                  />
                </div>
                 <b className="banner3request10">Login With Username</b> 
              </div>
            </button> */}
        </div>
        {/* )} */}
        {/* {!showLoginWithSocialMedia && (
          <div className="login-with-username-container">
            <div className="login-with-username-ui">
              <button className="back-button" onClick={onBackButtonClick}>
                <div className="backicon">
                  <img
                    className="back-icon-image"
                    alt=""
                    src="/back-icon-image.svg"
                  />
                </div>
                <div className="back-title">Back</div>
              </button>
              <div className="title117">Login with Username</div>
              <div className="password">
                <div className="input-label-username">Username</div>
                <Input
                  className="inputfield"
                  placeholder="Enter Username"
                  bordered={true}
                />
              </div>
              <div className="password">
                <div className="input-label-username">Password</div>
                <Input
                  className="inputfield"
                  placeholder="Enter Password"
                  bordered={true}
                />
              </div>
            </div>
            <button className="primarybutton">
              <div className="meetingicon10">
                <img className="vector-icon24" alt="" src="/vector.svg" />
              </div>
              <b className="banner3request13">Login</b>
              <div className="meetingicon11">
                <img className="vector-icon24" alt="" src="/vector.svg" />
              </div>
            </button>
          </div>
        )} */}
      </div>
      <div className="graphics">
        <DotLottiePlayer
          className="lottie-image"
          autoplay
          src="/demoapp/LoginPage.json"
          loop
        />
      </div>
    </div>
  );
};

export default LoginInnerContainer;
