//Store
import { store } from '../store';

//Constants
import { ERROR_NOTIFICATION, WARNING_NOTIFICATION, SUCCESS_NOTIFICATION, CLEAR_NOTIFICATIONS } from '../ReduxConstants';
import { delay } from '../../Utility/Utils';

export async function setErrorNotification(message: string | undefined) {
    store.dispatch({
        type: ERROR_NOTIFICATION,
        payload: message
    })
    await delay(2000);
    store.dispatch({ type: CLEAR_NOTIFICATIONS });
}

export async function setWarningNotification(message: string) {
    store.dispatch({
        type: WARNING_NOTIFICATION,
        payload: message
    })
    await delay(2000);
    store.dispatch({ type: CLEAR_NOTIFICATIONS });
}

export async function setSuccessNotification(message: string) {
    store.dispatch({
        type: SUCCESS_NOTIFICATION,
        payload: message
    })
    await delay(2000);
    store.dispatch({ type: CLEAR_NOTIFICATIONS });
}