export interface SalesState {
  meetingId: string;
  participantId: string;
  participantName: string;
  loginWithSocialMedia: boolean;
}

// Define the initial state for the ProConf reducer
const initialState: SalesState = {
  meetingId: "",
  participantId: "",
  participantName: "",
  loginWithSocialMedia: false,
};

// Reducer function to handle state changes based on actions
const SalesReducer = (state = initialState, action: any): SalesState => {
  switch (action.type) {
    case "SET_MEETING_ID":
      return { ...state, meetingId: action.payload };

    case "SET_PARTICIPANT_ID":
      return { ...state, participantId: action.payload };

    case "SET_PARTICIPANT_NAME":
      return { ...state, participantName: action.payload };

    case "SET_SOCIAL_LOGIN":
      return { ...state, loginWithSocialMedia: action.payload };

    default:
      return state;
  }
};

export default SalesReducer;
