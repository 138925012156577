import { InCallState } from "../StateTypes/StateTypes";
import { InCallActionTypes } from "../ActionTypes/InCallActionTypes";
import {
  SET_TRANSCRIPTIONS,
  CLEAR_INCALL_REDUCER,
  SET_ROOM_ENDED,
  SET_AUDIO_INPUT_DEVICES,
  SET_AUDIO_OUTPUT_DEVICES,
  SET_VIDEO_DEVICES,
  SET_AUDIO_PERMISSION,
  SET_VIDEO_PERMISSION,
  CLEAR_DEVICES,
} from "../ReduxConstants";

// Define the initial state for the room reducer
const initialState: InCallState = {
  transcriptions: [],
  roomEnded: false,
  audioInputDevices: [],
  audioOutputDevices: [],
  videoDevices: [],
  audioPermission: true,
  videoPermission: true,
};

// Reducer function to handle state changes based on actions
const InCallReducer = (
  state = initialState,
  action: InCallActionTypes
): InCallState => {
  switch (action.type) {
    case SET_TRANSCRIPTIONS:
      return {
        ...state,
        transcriptions: [...state.transcriptions, action.payload],
      };

    case CLEAR_INCALL_REDUCER:
      return {
        ...state,
        transcriptions: [],
        roomEnded: false,
        audioInputDevices: [],
        audioOutputDevices: [],
        videoDevices: [],
        audioPermission: true,
        videoPermission: true,
      };

    case SET_ROOM_ENDED:
      return {
        ...state,
        roomEnded: action.payload,
      };
    case SET_AUDIO_INPUT_DEVICES:
      return {
        ...state,
        audioInputDevices: action.payload,
      };

    case SET_AUDIO_OUTPUT_DEVICES:
      return {
        ...state,
        audioOutputDevices: action.payload,
      };

    case SET_VIDEO_DEVICES:
      return {
        ...state,
        videoDevices: action.payload,
      };

    case SET_AUDIO_PERMISSION:
      return {
        ...state,
        audioPermission: action.payload,
      };
    case SET_VIDEO_PERMISSION:
      return {
        ...state,
        videoPermission: action.payload,
      };
    case CLEAR_DEVICES:
      return {
        ...state,
        audioInputDevices: [],
        audioOutputDevices: [],
        videoDevices: [],
      };

    default:
      return state;
  }
};

export default InCallReducer;
