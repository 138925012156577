import { FunctionComponent, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import "./CallHeader.scss";
import TimeandRecordIcon from "../TimeandRecordIcon/TimeandRecordIcon";
import { calculateMeetingTime } from "../../Utility/Utils";
import { store } from "../../Redux/store";

export type CallHeaderType = {
  className?: string;
  showRecordingIcon?: boolean;
};

const CallHeader: FunctionComponent<CallHeaderType> = ({
  className = "",
  showRecordingIcon,
}) => {
  const meetingName = useSelector((state: any) => state?.RoomReducer?.roomName);
  const [timeSpent, setTimeSpent] = useState("");
  const unixTimestamp = useSelector(
    (state: any) => state?.RoomReducer?.meetingTime
  );

  useEffect(() => {
    // Cleanup interval on unmount
    return () => {
      store.dispatch({ type: "SET_MEETING_TIME", payload: "" });
    };
  }, []);

  useEffect(() => {
    if (unixTimestamp) {
      // Calculate initially
      let tt = calculateMeetingTime(parseInt(unixTimestamp));
      setTimeSpent(tt);

      // Update every second
      const interval = setInterval(() => {
        tt = calculateMeetingTime(parseInt(unixTimestamp));
        setTimeSpent(tt);
      }, 1000);

      // Cleanup interval on unmount
      return () => {
        clearInterval(interval);
      };
    }
  }, [unixTimestamp]);

  return (
    <div className={`callheader ${className}`}>
      <div className="meetingnameandtime">
        <b className="incallmeetingname">{meetingName}</b>
        <TimeandRecordIcon
          meetingTime={timeSpent}
          showRecordingIcon={showRecordingIcon}
        />
      </div>
    </div>
  );
};

export default CallHeader;
