//Store
import { store } from "../store";

//Constants
import {
    CLEAR_ROOM,
    CLEAR_ROOM_REDUCER,
    CLEAR_INCALL_REDUCER,
    LOGOUT,
    RESET_TOOLBAR_STATE,
    SET_REMOTE_SS
} from "../ReduxConstants";

//Services
import proConfService from "../../Services/ProConfService";


/**
 * @method setDataIntoReduxStore
 * @description dispatching/storing the data into the Redux store
*/
export function setDataIntoReduxStore(type: string, payload: any) {
    store.dispatch({ type, payload });
}

export async function clearMeetingDataFromStore(logout: boolean = false) {

    proConfService.clearTracks();
    await proConfService.cleanUp();

    store.dispatch({ type: CLEAR_INCALL_REDUCER, payload: false });
    store.dispatch({ type: CLEAR_ROOM_REDUCER });
    store.dispatch({ type: CLEAR_ROOM });
    store.dispatch({ type: RESET_TOOLBAR_STATE });
    store.dispatch({ type: SET_REMOTE_SS, payload: false });
    store.dispatch({ type: CLEAR_INCALL_REDUCER, payload: false });

    if (logout) {
        store.dispatch({ type: LOGOUT });
    }
}