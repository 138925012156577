import { useCallback, useRef, useState } from "react";
import { useSelector } from "react-redux";

//Custom Components
import PortalPopup from "../../Common Components/PortalPopup/PortalPopup";
import Logout from "../../Common Components/LogoutMenu/Logout";
import ProfileMenu from "../../Common Components/ProfileMenu/ProfileMenu";
import MeetingSummary from "../../Common Components/MeetingSummary/MeetingSummary";

//Translation
import { getTranslation } from "../../Resources/Localization/i18n";

//Assets
import logo from "../../Resources/Images/logo@2x.png";
import Illustration from "../../Resources/Images/Illustration1@2x.png";
import union from "../../Resources/Images/union.svg";
import Illustration2 from "../../Resources/Images/Illustration2@2x.png";

//Utility
import { getFormattedDateTime } from "../../Utility/Utils";

//Styles
import "./LandingPage.scss";
import { Summary } from "../../Redux/Actions/MeetingListAction";

interface LandingPageProps {
  meetingList: any;
  getCallSummary: (uniqueId: string) => Promise<Summary>;
  onCreateMeetingClick: () => {};
  joinMeeting: () => {};
  onLogoutClick: () => void;
}

interface Meeting {
  room_id: string;
  room_name: string;
  unique_id: string;
  is_summary_available: boolean;
  created_at: string;
}

const LandingPage: React.FC<LandingPageProps> = ({
  meetingList,
  getCallSummary,
  /*summary,*/ onCreateMeetingClick,
  joinMeeting,
  onLogoutClick /*setCallSummary*/,
}) => {
  //state variables
  const [isLogoutPopupOpen, setLogoutPopupOpen] = useState(false);
  const [isSummaryPopUpOpen, setIsSummaryPopUpOpen] = useState(false);
  const [summary, setCallSummary] = useState<Summary>({
    meeting_title: "",
    start_time: "",
    discussion_points: [],
    action_items: [],
    conclusions: [],
    attendees: [],
  });

  const isInitDone = useSelector(
    (state: any) => state.ProConfReducer.isInitDone
  );

  //ref
  const profileRef = useRef<HTMLButtonElement>(null);

  /* #region Event handlers */

  const toggleLogoutPopup = useCallback(() => {
    setLogoutPopupOpen(!isLogoutPopupOpen);
  }, [isLogoutPopupOpen]);

  /**
   * close the logout dropdown
   */
  const onLogout = useCallback(() => {
    toggleLogoutPopup();
    onLogoutClick();
  }, []);

  /**
   * @method onOpenSummary
   * @param meeting selected meeting
   * @description get the details of the selected meeting
   */
  const onOpenSummary = async (roomId: string) => {
    const summary = await getCallSummary(roomId);
    setCallSummary(summary);
    setIsSummaryPopUpOpen(true);
  };

  const onCloseSummary = () => {
    setIsSummaryPopUpOpen(false);
    setCallSummary({
      meeting_title: "",
      start_time: "",
      discussion_points: [],
      action_items: [],
      conclusions: [],
      attendees: [],
    });
  };
  /* #endregion */

  /* #region Renderers */

  /**
   *
   * @returns jsx for logout dropdown
   * will be displayed onclick of profile
   */
  const renderLogoutDropdown = () => {
    return (
      <PortalPopup
        placement="Bottom right"
        relativeLayerRef={profileRef}
        onOutsideClick={toggleLogoutPopup}
      >
        <Logout onLogoutClick={onLogout} />
      </PortalPopup>
    );
  };

  /**
   *
   * @returns jsx for banner/hero section on the landing page
   */
  const renderBannerContent = () => {
    return (
      <div className="bannercontent">
        <img
          className="bannerimageleft-icon"
          alt="Man on a video call"
          src={Illustration}
        />
        <div className="content1">
          <div className="heading">{getTranslation("seamlessVideo")}</div>
          <div className="actions">
            <button
              className="buttonprimarywithicon"
              disabled={!isInitDone}
              onClick={onCreateMeetingClick}
            >
              <div className="icadd">
                <img className="union-icon12" alt="" src={union} />
              </div>
              <b className="createmeeting">{getTranslation("createMeeting")}</b>
            </button>
          </div>
        </div>
        <img
          className="bannerimageright-1-icon"
          alt=" Video call illustration"
          src={Illustration2}
        />
      </div>
    );
  };

  /**
   *
   * @returns jsx to render list of previous meetings
   * fetched from web-sdk
   */
  const renderMeetingList = () => {
    return meetingList?.map((meeting: Meeting) => {
      return (
        <div className="cardrows" key={meeting.unique_id}>
          <div className="datetime">
            <div className="time">
              {getFormattedDateTime(meeting?.created_at)}
            </div>
          </div>
          <div className="meetingname">
            <b className="meetingname1">{meeting.room_name}</b>
          </div>
          {meeting.is_summary_available ? (
            <button
              className="textlink cursor-pointer"
              onClick={() => onOpenSummary(meeting.room_id)}
            >
              <b className="linktext">{getTranslation("viewSummary")}</b>
            </button>
          ) : (
            <span className="textlink">
              <b className="linktext">--</b>
            </span>
          )}
        </div>
      );
    });
  };

  /**
   *
   * @returns jsx for view summary of the past meeting
   * It will be displayed onclick of profile
   */
  const renderViewSummary = () => {
    return (
      <PortalPopup className="join-meeting-popup-container">
        <MeetingSummary summary={summary} onClose={onCloseSummary} />
      </PortalPopup>
    );
  };

  /* #endregion */

  return (
    <>
      <div className="landing-page">
        <div className="banner1">
          <header className="header1">
            <div className="nav-items1">
              <img className="logo-icon2" alt="" src={logo} />
              <ProfileMenu
                className="profile1"
                buttonRef={profileRef}
                toggleLogoutPopup={toggleLogoutPopup}
              />
            </div>
          </header>
          {renderBannerContent()}
        </div>
        <div className="meetingsection">
          <div className="pastmeetinglist">
            <div className="meetinglabel">
              <b className="meetingtext">{getTranslation("pastMeetings")}</b>
            </div>
            <div className="listofmeetings">
              {renderMeetingList()}
              {isSummaryPopUpOpen && renderViewSummary()}
            </div>
          </div>
        </div>
      </div>

      {isLogoutPopupOpen && renderLogoutDropdown()}
    </>
  );
};

export default LandingPage;
