import { FunctionComponent } from "react";
import "./NameTag.scss";
import { ReactComponent as MicOffNameTag } from "../../Resources/InCallImages/MicOffNameTag.svg";
import { ReactComponent as MicOnNameTag } from "../../Resources/InCallImages/MicOnNameTag.svg";

export type NameTagType = {
  className?: string;
  participantName?: string;
  showMicOffIcon?: boolean;
};

const NameTag: FunctionComponent<NameTagType> = ({
  className = "",
  participantName,
  showMicOffIcon,
}) => {
  return (
    <div className={`nametag ${className}`}>
      {showMicOffIcon && <MicOffNameTag className="micoff-icon" />}
      {!showMicOffIcon && <MicOnNameTag className="micon" />}
      <div className="name">{participantName}</div>
    </div>
  );
};

export default NameTag;
