import { FunctionComponent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

//Components
import CreateMeeting from "./MeetingPreview";
import LoadingSpinner from "../../Common Components/LoadingSpinner/LoadingSpinner";

//Routes
import { ROUTES } from "../../Routes/RouteConstants";

//Actions
import { logoutAction } from "../../Redux/Actions/LoginAction";
import {
  createMeeting,
  getCallStatus,
  getCatchup,
  joinMeeting,
} from "../../Redux/Actions/MeetingListAction";

//Services
import proConfService from "../../Services/ProConfService";
import MeetingCatchup from "../MeetingCatchup/MeetingCatchup";
import PortalPopup from "../../Common Components/PortalPopup/PortalPopup";

import "./MeetingPreview.scss";
import { updateRoomId } from "../../Sales-Flow/Redux/Actions/SalesAction";

const CreateMeetingContainer: FunctionComponent = () => {
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);
  const [catchUpText, setCatchupText] = useState("");
  const [roomName, setRoomName] = useState(
    useSelector((state: any) => state.RoomReducer?.roomName.trim()) || ""
  );

  const userName = useSelector((state: any) => state.RoomReducer.userName);
  const roomId = useSelector((state: any) => state.RoomReducer.roomId);
  const isCreateMeeting = useSelector(
    (state: any) => state.RoomReducer.isCreatemeeting
  );
  const loggedInUserName = useSelector(
    (state: any) => state.LoginReducer.userName
  );
  // const isInitDone = useSelector(
  //   (state: any) => state.ProConfReducer.isInitDone
  // );

  const isJoinFlow = userName ?? false;
  const [enableStartMeetingButton, setEnableStartMeetingButton] =
    useState(false);

  // Catchup
  const [showCatchupUI, setShowCatchupUI] = useState(false);
  const [isProceedClicked, setProceedClicked] = useState(false);

  /**
   * @method onBackClick
   * @description Go back to the previous page either Login Screen or Landing Page
   */
  const onBackClick = () => {
    // Navigate user to schedule demo page i.e ROOT_PATH
    proConfService.clearTracks();
    if (isJoinFlow) {
      navigate(ROUTES.ROOT_PATH);
    } else {
      navigate(ROUTES.ROOT_PATH);
    }
  };

  /**
   * @method onStartClick
   * @description To start/join the meeting if user is moderator and join meeting for guest
   */
  const onStartClick = async (roomName: string) => {
    setIsLoading(true);
    if (!isJoinFlow) {
      // Create meeting button click
      try {
        await createMeeting(roomName, loggedInUserName);
        setRoomName(roomName);
        updateRoomId();
        navigate(ROUTES.IN_CALL);
      } catch (err) {
        setEnableStartMeetingButton(true);
        setRoomName("");
      }
    } else {
      try {
        const res: any = await getCallStatus(roomId);
        // Check if user can see call catchup before joining existing meeting
        if (res?.transcript_status === true && res?.is_catchup_available) {
          await onViewCatchup();
        } else {
          await proceedToNormalInCallFlow(roomName);
        }
      } catch (err) {
        await proceedToNormalInCallFlow(roomName);
      }
      setEnableStartMeetingButton(true);
    }
    setIsLoading(false);
  };

  // useEffect(() => {
  //   if (!isJoinFlow && roomId) {
  //     navigate(ROUTES.IN_CALL);
  //   }
  // }, [roomId, roomName, isJoinFlow]);

  /**
   * @method proceedToNormalInCallFlow
   * @description Enter meeting based on whether user is moderator or guest
   */
  const proceedToNormalInCallFlow = async (room?: string) => {
    setIsLoading(true);
    const roomname = room || roomName;

    try {
      if (isJoinFlow) {
        // Guest user
        await joinMeeting(roomname, roomId, userName);
      } else if (!isCreateMeeting) {
        // Join meeting button click for moderator
        await joinMeeting(roomname, roomId, loggedInUserName);
      }
      setIsLoading(false);

      navigate(ROUTES.IN_CALL);
    } catch (err) {
      if (showCatchupUI) {
        setShowCatchupUI(false);
        window.history.back();
      }
      setIsLoading(false);
    }
  };

  /**
   * @description Handles the logout functionality by calling the logout action and navigating to the root page.
   * @method onLogoutClick
   * @return void
   */
  const onLogoutClick = () => {
    logoutAction();
    navigate("/");
  };

  /**
   * @method onViewCatchup
   * @description Call get call catchup API and display the catchup UI
   */
  const onViewCatchup = async () => {
    try {
      const res: any = await getCatchup(roomId); // api call
      if (res?.summary?.length > 0) {
        setCatchupText(res.summary);
        setShowCatchupUI(true);
      } else {
        await proceedToNormalInCallFlow(roomName);
      }
    } catch (err: any) {
      console.error("Error is getCallCatchup:", err);
      await proceedToNormalInCallFlow(roomName);
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading && <LoadingSpinner />}

      <CreateMeeting
        onBackClick={onBackClick}
        onStartClick={onStartClick}
        onLogoutClick={onLogoutClick}
        isJoinFlow={isJoinFlow}
        isCreateMeeting={isCreateMeeting}
        enableStartMeetingButton={enableStartMeetingButton}
        roomNameToJoin={roomName}
      />

      {/* Catchup confirmation popup
      {showCatchupPopup && (
        <PortalPopup placement="Centered">
          <CatchupDialog
            onClose={() => onCloseCatchup()}
            onView={() => onViewCatchup()}
          />
        </PortalPopup>
      )} */}

      {/* UI that displays meeting catchup*/}
      {showCatchupUI && (
        <PortalPopup
          placement="Centered"
          className="join-meeting-popup-container"
        >
          <div className="catchup-popup">
            <MeetingCatchup
              // onClose={() => onCloseCatchupUI()}
              onJoin={() => {
                setProceedClicked(true);
                proceedToNormalInCallFlow(roomName);
              }}
              text={catchUpText}
              isProceedClicked={isProceedClicked}
            />
          </div>
        </PortalPopup>
      )}
    </>
  );
};

export default CreateMeetingContainer;
